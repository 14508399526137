import { normalizeTargetingParameter } from '@/utils/helpers';

export type WindowWithAdriver = Window &
  typeof globalThis & {
    adriver?: unknown;
    adriverExtentionLoad?: (a: number, b: null, cId: string) => void;
    ActiveXObject: any;
  };

export type TargetParams = {
  category?: string;
  subcategory?: string;
  price?: number | null;
  make?: string;
  new?: string;
  type?: string;
  city?: string;
};

class CustomArray extends Array<string> {
  getStd(): string {
    for (var i = 0, j, s = []; i < this.length; i++) {
      if (this[i]) s.push((!j ? ((j = 1), i + '=') : '') + escape(this[i]));
      else j = 0;
    }
    return s.length ? '&custom=' + s.join(';') : '';
  }
}

const init = async (
  L: string,
  container_id: string,
  ar_cn?: number | null,
  target: TargetParams = {}
) => {
  // process.env.NODE_ENV === 'production' works also for the Preview on the Vercel
  // use isProduction() helper to check for production mode only
  if (
    !process.browser ||
    process.env.NODE_ENV !== 'production' ||
    process.env.CI
  )
    return null;

  const custom = new CustomArray();

  if (Object.entries(target).length) {
    custom[1] = normalizeTargetingParameter(target?.category);
    custom[2] = normalizeTargetingParameter(target?.subcategory);
    custom[3] = target?.price?.toString() || '';
    custom[4] = normalizeTargetingParameter(target?.make);
    custom[5] = normalizeTargetingParameter(target?.new);
    custom[6] = normalizeTargetingParameter(target?.type);
    custom[7] = normalizeTargetingParameter(target?.city);
  }

  if (typeof ar_cn === 'undefined' || ar_cn === null) {
    ar_cn = 1;
  }

  function getSZ() {
    const location = window.location;
    const a = location.hostname.split('.');
    a.splice(a.length - 2, 2);
    return (
      (a.length ? '/' + a.join('/') : '') +
      location.pathname +
      escape(location.search)
    );
  }

  let S =
      'setTimeout(function(e){if(!self.CgiHref){document.close();e=parent.document.getElementById("ar_container_"+ar_bnum);e.parentNode.removeChild(e);}},3000);',
    j = ' type="text/javascript"',
    t = 0,
    D = document,
    n = ar_cn;
  L =
    '' +
    ('https:' == document.location.protocol ? 'https:' : 'http:') +
    '' +
    L +
    encodeURIComponent(D.referrer || 'unknown') +
    '&sz=' +
    getSZ() +
    '&rnd=' +
    Math.round(Math.random() * 999999999) +
    custom.getStd();
  function _() {
    if (t++ < 100) {
      const F = D.getElementById('ar_container_' + n) as HTMLIFrameElement;
      if (F) {
        try {
          const d =
            F.contentDocument ||
            ((window as WindowWithAdriver).ActiveXObject &&
              // eslint-disable-next-line @typescript-eslint/ban-ts-comment
              // @ts-ignore
              window.frames['ar_container_' + n].document);
          if (d) {
            d.write(
              '<sc' +
                'ript' +
                j +
                '>var ar_bnum=' +
                n +
                ';' +
                S +
                '</sc' +
                'ript><sc' +
                'ript' +
                j +
                ' src="' +
                L +
                `" onload="parent.adriverExtentionLoad?.(ar_adid,bid, '${container_id}')"><\/sc` +
                'ript>'
            );
            t = 0;
          } else setTimeout(_, 100);
        } catch (e) {
          try {
            F.src =
              "javascript:{document.write('<sc'+'ript" +
              j +
              '>var ar_bnum=' +
              n +
              '; document.domain="' +
              D.domain +
              '";' +
              S +
              "</sc'+'ript>');document.write('<sc'+'ript" +
              j +
              ' src="' +
              L +
              `" onload=\"parent.adriverExtentionLoad?.(ar_adid,bid, '${container_id}')\"><\/sc'+'ript>');}`;
            return;
          } catch (E) {}
        }
      } else setTimeout(_, 100);
    }
  }

  const c = D.querySelector(`#${container_id}`);
  if (c) {
    c.innerHTML = `<div style="visibility:hidden;height:0;left:-1000px;position:absolute;"><iframe id="ar_container_${ar_cn}" width=1 height=1 marginwidth=0 marginheight=0 scrolling=no frameborder=0></iframe></div><div id="ad_ph_${ar_cn}" style="display:none;"></div>`;
  }
  _();
};

export const callBanner = (
  container_id: string,
  sid: string,
  bn: string,
  arCn?: number,
  target?: TargetParams
) => {
  const link = `//ad.adriver.ru/cgi-bin/erle.cgi?sid=${sid}&bn=${bn}&bt=43&pz=0&target=top&tail256=`;
  const initialize = async () => await init(link, container_id, arCn, target);
  initialize();
};
