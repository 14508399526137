import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useRouter } from 'next/router';
import { PageTopControls } from '@/components/PageTopControls/PageTopControls';
import { IconBox } from '@/components/UI/IconBox/IconBox.styles';
import * as S from '@/views/Error/Error.styles';
import { ROUTES } from '@/constants/routes';
import { CommonTestIds } from '@/__e2e__/testIds/common';
import { useBanner } from '@/hooks/useBanner';

interface ErrorViewProps {
  title?: string;
  subtitle?: string;
  statusCode?: number;
  buttonText?: string;
  onClick?: () => void;
  hasTopBanner?: boolean;
}

export const ErrorView: React.FC<ErrorViewProps> = ({
  title,
  subtitle,
  statusCode,
  buttonText,
  onClick,
  hasTopBanner,
}) => {
  const { t } = useTranslation();
  const router = useRouter();
  const { topBanner } = useBanner();
  const isUnexpectedError = statusCode === 500;
  const isNotFound = statusCode === 404;
  const imageSrc = isUnexpectedError
    ? '/images/unexpected-error.png'
    : '/images/not-found.png';
  const finalTitle =
    title ??
    t(isNotFound ? 'pages.error.not_found' : 'pages.error.unexpected_error');

  const finalSubtitle =
    subtitle ??
    t(
      isNotFound
        ? 'pages.error.not_found_subtitle'
        : 'pages.error.unexpected_error_subtitle'
    );

  const finalButtonText =
    buttonText ??
    t(isNotFound ? 'pages.error.go_to_main' : 'pages.error.try_again');

  function onButtonClick() {
    if (onClick) return onClick();
    router.replace(ROUTES.home);
  }

  useEffect(() => {
    document.body.classList.add('dynamic-height');
    return () => document.body.classList.remove('dynamic-height');
  }, []);

  return (
    <>
      {hasTopBanner && topBanner}
      <PageTopControls isMainHeader />
      <S.Box>
        <S.Container>
          <IconBox
            $src={imageSrc}
            width={255}
            height={218}
            data-testid={
              isUnexpectedError
                ? CommonTestIds.errorPage.unexpectedErrorImage
                : CommonTestIds.errorPage.notFoundImage
            }
          />

          <S.Title $variant="3" data-testid={CommonTestIds.errorPage.title}>
            {finalTitle}
          </S.Title>

          <S.Text $variant="1" data-testid={CommonTestIds.errorPage.subtitle}>
            {finalSubtitle}
          </S.Text>

          {(isNotFound || isUnexpectedError || (buttonText && onClick)) && (
            <S.Action
              appearance="secondary"
              isFullWidth
              onClick={onButtonClick}
            >
              {finalButtonText}
            </S.Action>
          )}
        </S.Container>
      </S.Box>
    </>
  );
};
