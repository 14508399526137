import { useEffect } from 'react';
import { withPreRenderSettings } from '@/utils/hocs';
import { ErrorView } from '@/views/Error/Error.view';
import { logError } from '@/utils/log-error';

function ErrorPage({ statusCode }: { statusCode: number }) {
  useEffect(() => {
    logError(`[ErrorPage]: statusCode: ${statusCode}`);
  }, []);

  return <ErrorView statusCode={statusCode} />;
}

ErrorPage.getInitialProps = withPreRenderSettings(ctx => {
  return { props: { statusCode: ctx.res?.statusCode } };
}, true);

export default ErrorPage;
