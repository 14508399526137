// @ts-nocheck
/* eslint-disable */

import React, { useEffect } from 'react';
import NoSsr from '@/components/NoSsr/NoSsr';
import { BannerName } from '@/components/Banners/Banners.types';
import { AdManagerBanners } from '@/components/Banners/Banners.constants';

export interface Props {
  bannerName: BannerName;
}

export const AdManagerBanner = ({ bannerName }: Props) => {
  useEffect(() => {
    if (typeof window !== 'undefined') {
      const googletag = window.googletag || { cmd: [] };
      googletag.cmd.push(() => {
        googletag
          .defineSlot(
            AdManagerBanners[bannerName].bannerId,
            AdManagerBanners[bannerName].sizes,
            AdManagerBanners[bannerName].blockId
          )
          ?.addService(googletag.pubads());

        googletag.enableServices();
      });

      setTimeout(() => {
        googletag.cmd.push(() => {
          googletag.display(AdManagerBanners[bannerName].blockId);
        });
      }, 0);
    }
  }, []);

  return (
    <NoSsr>
      <div
        className={AdManagerBanners[bannerName].blockId}
        id={AdManagerBanners[bannerName].blockId}
      />
    </NoSsr>
  );
};
